import { Box, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import CategoriesSections from '../components/pages/documentsList/categoriesSection'
import SEO from '../components/seo'
import useAllDocumentsCategories from '../hooks/useAllDocumentsCategories'
import useAllDocumentsListCategories from '../hooks/useAllDocumentsListCategories'
import { RootState } from '../redux/store'
import { getInternationalizedValue } from '../utils/common'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // marginTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  titleContainer: {
    textAlign: 'center',
    textTransform: 'uppercase',
    // paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  container: {
    padding: `0px ${theme.spacing(2)}px`,
    borderRadius: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(0.5),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },

    background:
      'linear-gradient(180deg, #C9FFEF 0%, rgba(255, 255, 255, 0) 100%), #FFF',
  },
}))

const DocumentsList: FC = () => {
  const classes = useStyles()

  const { language } = useSelector((state: RootState) => state.app)

  const allTitleData = useAllDocumentsCategories()
  let bigTitle = 'Liste des documents'
  if (allTitleData.length) {
    bigTitle = getInternationalizedValue(allTitleData[0], 'title', language)
  }

  const categoriesData = useAllDocumentsListCategories()

  return (
    <Box className={classes.root}>
      <SEO title="List des documents" />
      <Box className={classes.titleContainer}>
        <Typography variant="h4">{bigTitle}</Typography>
      </Box>

      <Box className={classes.container}>
        {categoriesData
          ? categoriesData.map(c => <CategoriesSections key={c.id} data={c} />)
          : null}
      </Box>
    </Box>
  )
}

export default DocumentsList
