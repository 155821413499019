import { Box, Theme, Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { AllDocumentsListCategories } from '../../../../hooks/useAllDocumentsListCategories'
import { RootState } from '../../../../redux/store'
import { getInternationalizedValue } from '../../../../utils/common'
import DownloadCase from '../../documentsLegalForm/downloadCase'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(6),
  },
  title: {
    fontWeight: 'bold',
  },
}))

interface CategoriesSectionsProps {
  data: AllDocumentsListCategories
}

const CategoriesSections: FC<CategoriesSectionsProps> = props => {
  const { data } = props
  const classes = useStyles()

  const { language } = useSelector((state: RootState) => state.app)

  const sectionTitle = getInternationalizedValue(
    data,
    'title_category',
    language,
  )

  const documentsList = data.documents_lists

  return (
    <Box className={classes.root}>
      <Typography variant="h5" className={classes.title}>
        {sectionTitle}
      </Typography>

      <Box marginY={2}>
        {documentsList.map((d, index) => (
          <Box key={`download-form-${d.id}`}>
            <DownloadCase data={d} />
            {index + 1 !== documentsList.length ? <Divider /> : null}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default CategoriesSections
