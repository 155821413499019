import { graphql, useStaticQuery } from 'gatsby'

export interface AllDocumentsCategories {
  id: string
  title_en: string
  title_fr: string
  title_mg: string
}

export default (): AllDocumentsCategories[] => {
  const data = useStaticQuery(graphql`
    {
      allStrapiDocumentListWithCategories {
        nodes {
          id
          title_en
          title_fr
          title_mg
        }
      }
    }
  `)

  return data.allStrapiDocumentListWithCategories.nodes
}
