import { graphql, useStaticQuery } from 'gatsby'
import { DocumentsLegalFormLists } from './useDocumentsLegalForm'

export interface AllDocumentsListCategories {
  id: string
  title_category_mg: string
  title_category_fr: string
  title_category_en: string
  documents_lists: DocumentsLegalFormLists[]
}

export default (): AllDocumentsListCategories[] => {
  const data = useStaticQuery(graphql`
    {
      allStrapiDocumentCategories {
        nodes {
          id
          title_category_mg
          title_category_fr
          title_category_en
          documents_lists {
            id
            document_name_fr
            model_link_txt_fr
          }
        }
      }
    }
  `)

  return data.allStrapiDocumentCategories.nodes
}
